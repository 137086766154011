html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td
{
	margin:         0;
	padding:        0;
	border:         0;
	outline:        0;
	font-weight:    inherit;
	font-style:     inherit;
	font-family:    inherit;
	font-size:      100%;
	vertical-align: baseline;
}

body
{
	line-height: 1;
	color:       #222;
	background:  #fff;
}

ol,
ul
{
	list-style: none;
}

table
{
	border-collapse: separate;
	border-spacing:  0;
	vertical-align:  middle;
}

caption,
th,
td
{
	text-align:     left;
	font-weight:    normal;
	vertical-align: middle;
}

a img
{
	border: none;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main
{
	margin:  0;
	padding: 0;
	border:  0;
	outline: 0;
	display: block;
}

audio,
canvas,
video
{
	display:  inline-block;
}

audio:not([controls]),
[hidden]
{
	display: none;
}

html
{
	font-family:              sans-serif;
	-ms-text-size-adjust:     100%;
	-webkit-text-size-adjust: 100%;
}

body
{
	margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary
{
	display: block;
}

audio,
canvas,
progress,
video
{
	display:        inline-block;
	vertical-align: baseline;
}

audio:not([controls])
{
	display: none;
	height:  0;
}

[hidden],
template
{
	display: none;
}

a
{
	background: transparent;
}

a:active,
a:hover
{
	outline: 0;
}

abbr[title]
{
	border-bottom: 1px dotted;
}

b,
strong
{
	font-weight: bold;
}

dfn
{
	font-style: italic;
}

h1
{
	font-size: 2em;
	margin:    0.67em 0;
}

mark
{
	background: #ff0;
	color:      #000;
}

small
{
	font-size: 80%;
}

sub,
sup
{
	font-size:      75%;
	line-height:    0;
	position:       relative;
	vertical-align: baseline;
}

sup
{
	top: -0.5em;
}

sub
{
	bottom: -0.25em;
}

img
{
	border: 0;
}

svg:not(:root)
{
	overflow: hidden;
}

figure
{
	margin: 1em 40px;
}

hr
{
	-moz-box-sizing: content-box;
	box-sizing:      content-box;
	height:          0;
}

pre
{
	overflow: auto;
}

code,
kbd,
pre,
samp
{
	font-family: monospace, monospace;
	font-size:   1em;
}

button,
input,
optgroup,
select,
textarea
{
	color:  inherit;
	font:   inherit;
	margin: 0;
}

button
{
	overflow: visible;
}

button,
select
{
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"]
{
	-webkit-appearance: button;
	cursor:             pointer;
}

button[disabled],
html input[disabled]
{
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner
{
	border:  0;
	padding: 0;
}

input
{
	line-height: normal;
}

input[type="checkbox"],
input[type="radio"]
{
	box-sizing: border-box;
	padding:    0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button
{
	height: auto;
}

input[type="search"]
{
	-webkit-appearance: textfield;
	-moz-box-sizing:    content-box;
	-webkit-box-sizing: content-box;
	box-sizing:         content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration
{
	-webkit-appearance: none;
}

fieldset
{
	border:  1px solid #c0c0c0;
	margin:  0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend
{
	border:  0;
	padding: 0;
}

textarea
{
	overflow: auto;
}

optgroup
{
	font-weight: bold;
}

table
{
	border-collapse: collapse;
	border-spacing:  0;
}

td,
th
{
	padding: 0;
}

img,
video,
iframe
{
	max-width:              100%;
	height:                 auto;
	border:                 0;
	-ms-interpolation-mode: bicubic;
	display:                block;
}

@media print
{
	body
	{
		width:       100% !important;
		margin:      0 !important;
		padding:     0 !important;
		font-size:   14pt;
		line-height: 1.45;
		font-family: Garamond, "Times New Roman", serif;
		color:       #000;
		background:  none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6
	{
		page-break-after: avoid;
		font-family:      "Helvetica", Arial, sans-serif;
	}

	h1
	{
		font-size:   36pt;
		line-height: 1.25em;
	}

	h2
	{
		font-size:   24pt;
		line-height: 1.25em;
	}

	h3
	{
		font-size:   18pt;
		line-height: 1.25em;
	}

	h4,
	h5,
	h6
	{
		font-size:   14pt;
		line-height: 1.25em;
	}

	p,
	h2,
	h3
	{
		orphans: 3;
		widows:  3;
	}

	code
	{
		font: 12pt Courier, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
	}

	blockquote
	{
		margin:      1.2em;
		padding:     1em;
		font-size:   24pt;
		line-height: 1.45833333em;
		font-style:  italic;
	}

	hr
	{
		background-color: #ccc;
	}

	img
	{
		float:     left;
		margin:    1em 1.5em 1.5em 0;
		max-width: 100% !important;
	}

	a img
	{
		border: none;
	}

	a:link,
	a:visited
	{
		background:      transparent;
		font-weight:     700;
		text-decoration: underline;
		color:           #333;
	}

	a:link[href^="http://"]:after,
	a[href^="http://"]:visited:after
	{
		content:   " (" attr(href) ") ";
		font-size: 90%;
	}

	abbr[title]:after
	{
		content: " (" attr(title) ")";
	}

	a[href^="http://"]
	{
		color: #000;
	}

	a[href$=".jpg"]:after,
	a[href$=".jpeg"]:after,
	a[href$=".gif"]:after,
	a[href$=".png"]:after
	{
		content: " (" attr(href) ") ";
		display: none;
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after
	{
		content: "";
	}

	table
	{
		margin:     1px;
		text-align: left;
	}

	th
	{
		border-bottom: 1px solid #333;
		font-weight:   bold;
	}

	td
	{
		border-bottom: 1px solid #333;
	}

	th,
	td
	{
		padding: 4px 10px 4px 0;
	}

	tfoot
	{
		font-style: italic;
	}

	caption
	{
		background:    #fff;
		margin-bottom: 2em;
		text-align:    left;
	}

	thead
	{
		display: table-header-group;
	}

	img,
	tr
	{
		page-break-inside: avoid;
	}
}

html
{
	box-sizing: border-box;
}

*,
*:before,
*:after
{
	box-sizing: inherit;
}

.hidden
{
	display: none !important;
}

.invisible
{
	visibility: hidden;
}

.form-horizontal .form-group
{
	margin-left:  0 !important;
	margin-right: 0 !important;
}

.modal
{
	display:    block !important;
	border:     0 !important;
	background: none !important;
}

header .right
{
	float: right;
}

header .right .nav
{
	margin-bottom: 10px;
}

header .right .nav.links li
{
	text-transform: uppercase;
	font-size:      0.8em;
}

header .right .nav.info
{
	float: right;
}

header .right .nav.info li
{
	font-size: 0.9em;
}

ul.links
{
	margin:  0;
	padding: 0;
}

ul.links:after
{
	content: "";
	display: block;
	clear:   both;
}

ul.links li
{
	float:           left;
	margin-right:    8px;
	list-style-type: none;
}

ul.links li:last-child
{
	margin-right: 0;
}

ul.links li:not(:last-child):after
{
	content:     '|';
	margin-left: 8px;
	color:       #7c8082;
}

ul.links li
{
	color: #7c8082;
}

ul.links li a
{
	color:           #7c8082;
	text-decoration: none;
}

ul.links li a:hover
{
	text-decoration: underline;
}

ul.links li .em
{
	color: #444;
}

input
{
	padding:       8px 12px;
	border-radius: 4px;
	outline:       none;
	box-shadow:    none;
	border:        thin solid #ccc;
}

input.err
{
	border: thin solid #d13f19;
}

.field
{
	display:        inline-block;
	vertical-align: top;
}

.field input
{
	width: 100%;
}

.field.full
{
	clear:         both;
	margin-bottom: 5px;
	text-align:    left;
	width:         80%;
}

.field.half
{
	clear:         both;
	margin-bottom: 5px;
	text-align:    left;
	width:         40%;
}

.field.quarter
{
	clear:         both;
	margin-bottom: 5px;
	text-align:    left;
	width:         20%;
}

.field.eigth
{
	clear:         both;
	margin-bottom: 5px;
	text-align:    left;
	width:         12.5%;
}

form div.address
{
	border:        1px dashed #ccc;
	padding:       8px 12px;
	border-radius: 4px;
}

form div.address-value
{
	padding: 8px 12px;
}

form div.address-value span
{
	font-weight: bold;
}

.map
{
	width:  100%;
	height: 100%;
}

#service-address div.address-value
{
	margin-bottom: 20px;
	font-size:     1.1em;
	width:         50%;
	display:       block;
	float:         left;
}

#service-address .map
{
	width:   50%;
	display: block;
	float:   left;
}

td input
{
	width: 100%;
}
